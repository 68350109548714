// General URLs
export const URLS = {
  HOME: '/',
  CASINO: '/livecasino',
  ACCOUNT: '/account',
  GUIDE: '/huong-dan',
  PROMOTION: '/khuyen-mai',
  LIVESPORT: '/the-thao',
  NEWS: '/tin-tuc',
  SCHEDULES: '/the-thao/lich-thi-dau',
  GAME: '/game',
  ABOUT_US: 'gioi-thieu'
}

// Page URLs
export const PAGE_URLS = {
  ACCOUNT: URLS.ACCOUNT,
  HOME: URLS.HOME,
  NEWS: URLS.NEWS,
  GUIDE: URLS.GUIDE,
  SCHEDULES: URLS.SCHEDULES,
  DEPOSIT_CODEPAY_GUIDE: `${URLS.GUIDE}/deposit-codepay`,
  WITHDRAW_BANK_GUIDE: `${URLS.GUIDE}/withdraw-bank`,
  ACCOUNT_DEPOSIT: `${URLS.ACCOUNT}/deposit`,
  DEPOSIT_TA_PAY: `${URLS.ACCOUNT}/deposit/ta-pay`,
  ACCOUNT_INFO_USER: `${URLS.ACCOUNT}/information/user`,
  PROMOTION_DETAIL: `${URLS.PROMOTION}/detail`,
  ADD_BANK: `${URLS.ACCOUNT}/information/bank?currentTab=ADD_BANK`,
  PROMOTION_BONUS: `${URLS.PROMOTION}${URLS.PROMOTION}/bonus`,
  TRANSACTION_HISTORY: `${URLS.ACCOUNT}/history/transaction`,
  PROMOTION: `${URLS.PROMOTION}`,
  PROMOTION_VIP: `${URLS.PROMOTION}/vip`,
  PROMOTION_CATEGORY: `${URLS.PROMOTION}/the-loai`
}

// Lobby Game URLs
export const LOBBY_GAME_URLS = {
  ALL: URLS.GAME,
  LO_DE: `${URLS.GAME}/lo-de`,
  BAN_CA: `${URLS.GAME}/ban-ca`,
  QUAY_SO: `${URLS.GAME}/quay-so`,
  TABLE_GAME: `${URLS.GAME}/table-game`,
  GAME_BAI: `${URLS.GAME}/game-bai`,
  SLOTS_GAME: `${URLS.GAME}/slots`,
  NO_HU: `${URLS.GAME}/no-hu`,
  INGAME: `${URLS.GAME}/ingame`,
  QUICK_GAMES: `${URLS.GAME}/game-nhanh`,
  V_GAMING: `${URLS.GAME}/v-gaming`,
  LIVE_STREAM: `${URLS.GAME}/livestream`
}

// Lobby Casino URLs
export const LOBBY_CASINO_URLS = {
  ALL: URLS.CASINO,
  TAIXIU: `${URLS.CASINO}/taixiu`,
  XOCDIA: `${URLS.CASINO}/xocdia`,
  BAUCUA: `${URLS.CASINO}/baucua`,
  ROULETTE: `${URLS.CASINO}/roulette`,
  BACCARAT: `${URLS.CASINO}/baccarat`,
  POKER: `${URLS.CASINO}/poker`,
  SICBO: `${URLS.CASINO}/sicbo`,
  BLACKJACK: `${URLS.CASINO}/blackjack`,
  DRAGONTIGER: `${URLS.CASINO}/dragontiger`,
  OTHERS: `${URLS.CASINO}/others`,
  LIVESTREAM: `${URLS.CASINO}/livestream`
}

// Iframe Game URLs
export const IFRAME_GAME_URLS = {
  K_SPORTS: 'the-thao/ksports',
  C_SPORTS: 'the-thao/csports',
  P_SPORTS: 'the-thao/psports',
  E_SPORTS: 'the-thao/esports',
  VS_SPORTS: 'the-thao/vssports'
}

// Withdraw URLs
export const WITHDRAW_URLS = {
  BANK: `${URLS.ACCOUNT}/withdraw/bank`,
  CRYPTO: `${URLS.ACCOUNT}/withdraw/crypto`,
  CARD: `${URLS.ACCOUNT}/withdraw/card`
}
